import React from "react";
import {
  ModalContentContainer,
  ModalPE,
  ModalPEContainer,
  ProductsImageContainer,
  ModalProductImg,
  ProductInfoContainer,
  ModalProductImgs,
  Productdivider,
  ProductFirstSection,
  ProductSectionLeft,
  ProductSectionLeftText,
  ButtonRightContainer,
  CancelModalProduct,
  SaveChanges,
  DeleteProducts,
  DeleteSpinnerContainer,
  DeleteProductSpinner,
  ProductSectionRight,
  Content,
  Contents,
  ClickModalProduct,
  In,
  ImputPriceContainer,
  ImputPrice,
  ProductPEtName,
} from "./ModalProductEdition.element";
import { toast } from "react-hot-toast";
import { AfricaProductCategories, ChinaProductCategories } from "../data/data";
import DropdownSelectMenuWithSearch from "./DropdownSelectMenuWithSearch";
import { EuiBadge } from "@elastic/eui";
import useDeleteProduct from "../hooks/useDeleteProduct";
import useUpdateProduct from "../hooks/useUpdateProduct";

const ModalProductEdition = ({
  product,
  handleChangeModalState,
  onDeleteProduct,
  updateProduct,
}) => {
  const btnSpinner = React.useRef(null);
  const btnSpinners = React.useRef(null);
  const btnText = React.useRef(null);
  const btnTexts = React.useRef(null);

  const [categorySelected, setCategorySelected] = React.useState(
    product.category
  );
  const [stock, setStock] = React.useState(product.stock);
  const [loading, setLoading] = React.useState(false);
  const [loadings, setLoadings] = React.useState(false);
  const [priceVal, setPriceVal] = React.useState(product.price);
  const { deleteProduct } = useDeleteProduct();
  const { updateProducts } = useUpdateProduct();

  const handleUpadteProduct = async () => {
    setLoadings(true);

    const newData = {
      price: priceVal,
      stock: stock,
      category: categorySelected,
    };

    console.log(product.productId);
    console.log(product);

    await updateProducts(product, newData)
      .then(() => {
        updateProduct(product.uid, newData);
        handleChangeModalState();
        setLoadings(false);
        toast.success("Product successfully update!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error has occurred");
      });
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setPriceVal("");
    } else {
      setPriceVal(e.target.value);
    }
  };

  const handleChangeCategoryState = (category) => setCategorySelected(category);

  const handleDeleteProduct = async () => {
    setLoading(true);

    await deleteProduct(product.uid, product.images, product.videoUrl)
      .then(() => {
        onDeleteProduct(product.uid);
        handleChangeModalState();
        setLoading(false);
        toast.success("Product successfully deleted!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error has occurred");
      });
  };

  React.useEffect(() => {
    if (loading) {
      btnSpinner.current.style.display = "flex";
      btnText.current.style.display = "none";
    } else {
      btnSpinner.current.style.display = "none";
      btnText.current.style.display = "flex";
      btnText.current.textContent = "Delete Product";
    }
  }, [loading]);

  React.useEffect(() => {
    if (loadings) {
      btnSpinners.current.style.display = "flex";
      btnTexts.current.style.display = "none";
    } else {
      btnSpinners.current.style.display = "none";
      btnTexts.current.style.display = "flex";
      btnTexts.current.textContent = "Save change";
    }
  }, [loadings]);

  //<input type='file' accept='video/*'  onChange={handleChooseVideo} hidden/>

  return (
    <ModalPEContainer>
      <ModalPE>
        <ModalContentContainer>
          <ModalProductImg src={product.images[0]} />

          <ProductsImageContainer>
            {product.images.map((url, index) => (
              <ModalProductImgs key={index} src={url} />
            ))}
          </ProductsImageContainer>

          <Productdivider />

          <ProductInfoContainer>
            <ProductFirstSection>
              <ProductSectionLeft>
                <ProductSectionLeftText>Product name</ProductSectionLeftText>
              </ProductSectionLeft>

              <ProductSectionRight>
                <ImputPriceContainer>
                  <ProductPEtName>{product.productName}</ProductPEtName>
                </ImputPriceContainer>
              </ProductSectionRight>
            </ProductFirstSection>

            <Productdivider />

            <ProductFirstSection>
              <ProductSectionLeft>
                <ProductSectionLeftText>Product price</ProductSectionLeftText>
              </ProductSectionLeft>

              <ProductSectionRight>
                <ImputPriceContainer>
                  <ImputPrice
                    value={priceVal}
                    placeholder="Product price"
                    onInput={(e) => handleFilter(e)}
                  />
                </ImputPriceContainer>
              </ProductSectionRight>
            </ProductFirstSection>

            <Productdivider />

            <ProductFirstSection>
              <ProductSectionLeft>
                <ProductSectionLeftText>
                  Product category
                </ProductSectionLeftText>
              </ProductSectionLeft>

              <ProductSectionRight>
                <Content>
                  <DropdownSelectMenuWithSearch
                    data={
                      product.space === "africa"
                        ? AfricaProductCategories
                        : ChinaProductCategories
                    }
                    selectType="Select Product Category"
                    isSearch={false}
                    isLabel={false}
                    changeCategoryState={handleChangeCategoryState}
                    category={categorySelected}
                  />
                </Content>
              </ProductSectionRight>
            </ProductFirstSection>

            <Productdivider />

            <ProductFirstSection>
              <ProductSectionLeft>
                <ProductSectionLeftText>Product stock</ProductSectionLeftText>
              </ProductSectionLeft>

              <ProductSectionRight>
                <Contents>
                  <In>
                    {stock === true ? (
                      <EuiBadge color="success">In stock</EuiBadge>
                    ) : (
                      <EuiBadge color="danger">Stock out</EuiBadge>
                    )}
                  </In>

                  <ClickModalProduct onClick={() => setStock(!stock)}>
                    Click to change
                  </ClickModalProduct>
                </Contents>
              </ProductSectionRight>
            </ProductFirstSection>
            <Productdivider />
            <ProductFirstSection>
              <DeleteProducts onClick={handleDeleteProduct}>
                <DeleteSpinnerContainer ref={btnSpinner}>
                  <DeleteProductSpinner size={25} />
                </DeleteSpinnerContainer>
                <span ref={btnText}>Delete Product</span>
              </DeleteProducts>

              <ButtonRightContainer>
                <CancelModalProduct onClick={handleChangeModalState}>
                  Cancel
                </CancelModalProduct>

                <SaveChanges onClick={handleUpadteProduct}>
                  <DeleteSpinnerContainer ref={btnSpinners}>
                    <DeleteProductSpinner size={25} />
                  </DeleteSpinnerContainer>
                  <span ref={btnTexts}>Save changes</span>
                </SaveChanges>
              </ButtonRightContainer>
            </ProductFirstSection>
          </ProductInfoContainer>
        </ModalContentContainer>
      </ModalPE>
    </ModalPEContainer>
  );
};

export default ModalProductEdition;
